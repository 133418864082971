@import '_mixins/pux/breakpoints';
@import '_mixins/pux/css-rules';
@import '_variables';
@import '_mixins/pux/scale';

/***
inf pager
***/
.pux-infinite-repeater-generated-pager-container-container {
  position: relative;
  z-index: 22;
  padding: 20px 5px 0 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
}

@media (min-width: 1200px) {
  .pux-infinite-repeater-generated-pager-container-container {
    float: right;
    text-align: right;
  }
}

.pux-infinite-repeater-generated-pager-container {
  display: inline-block;
}

.pux-infinite-repeater-generated-pager-container .pager-prev {
  display: none;
}

.pux-infinite-repeater-generated-pager-container .pager-next {
  display: none;
}

.pux-infinite-repeater-generated-pager-container .products-grid-pager-numbers {
  max-width: 300px;
}

.pux-infinite-repeater-generated-pager-container {
  a {
    display: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0;

    li {
      display: inline-block;
      vertical-align: middle;
      padding: 0;
      margin: 0 0 0 10px;

      a {
        @include pux-static-font(16px);
        @include pux-static-size("width", 50px);  
        @include pux-static-size("height", 50px);  
        @include pux-static-size("line-height", 50px);
        font-weight: 700;
        text-align: center;
        text-decoration: none !important;
        display: inline-block;
        color: $base-white !important;
        background-color: $color-new-green;
        cursor: pointer;
    
        &:focus,
        &:hover {
          outline: none;
          color: $base-white !important;
          background-color: $color-new-green-dark;
        }
      }

      &.active {
        a {
          outline: none;
          color: $base-white !important;
          background-color: $color-new-green-dark;
        }
      }
    }
  }
}

.pux-infinite-repeater-generated-pager-container ul li:before {
  display: none;
}